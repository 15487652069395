const initialState = {
  balance: {},
  loading: false,
  transactionLoading: false,
  transactions:{}
};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_WALLET_BALANCE":
      return {
        ...state,
        balance: action.payload,
      };

    case "SET_BALANCE_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "RESET_BALANCE_LOADING":
      return {
        ...state,
        loading: false,
      };
      case "SET_WALLET_TRANSACTIONS":
        return {
          ...state,
          transactions: action.payload,
        };
    case "SET_TRANSACTIONS_LOADING":
      return {
        ...state,
        transactionLoading: true,
      };

    case "RESET_TRANSACTIONS_LOADING":
      return {
        ...state,
        transactionLoading: false,
      };
    
    default:
      return state;
  }
}
