import { Button, Card, Table, Typography, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Payments.css";
import { useAuth0 } from "@auth0/auth0-react";
import { withdrawFunds } from "../../actions/Actions";
import { formatAmount, formatdate } from "../../util";



function Payments() {
  const { isAuthenticated, user , getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Are you sure you want to withdraw funds?');
  const payments = useSelector((state) => state.payments);
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setModalText(`Initiated transaction to withdraw $${payments.balance.balance} from wallet to the linked account`);
    setConfirmLoading(true);
    if(isAuthenticated){
      getAccessTokenSilently().then((token)=>{
       if(currentDealerhost){
         dispatch(withdrawFunds(currentDealerhost?.dealer_id, token,user.email))
       }
      })
     }
  };

  useEffect(() => {
    if(!payments?.transactionLoading){
      setOpen(false);
      setConfirmLoading(false);
    }
  }, [payments.transactionLoading])
  
  const handleCancel = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      render: (text) => formatdate(text),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => formatAmount(text),
    },
    {
      title: 'Source Customer Name',
      dataIndex: ['source', 'customerName'],
      key: 'sourceCustomerName',
    },
    {
      title: 'Destination Customer Name',
      dataIndex: ['destination', 'customerName'],
      key: 'destinationCustomerName',
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: 'Transfer Status',
      dataIndex: 'transferStatus',
      key: 'transferStatus',
    },
    {
      title: 'Transfer Method',
      dataIndex: 'transferMethod',
      key: 'transferMethod',
    }
  ];
  return (
    <>
      <div
        className="balance-block"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Card
          title=" Total Balances"
          style={{
            width: 300,
            marginTop: 16,
          }}
          loading={payments.loading}
        >
          Total Account Value:<strong> ${payments.balance.balance}</strong>
        </Card>
          <Button type="primary" onClick={showModal}>Withdraw Balance</Button>
          <Modal
        title="Withdraw Funds"
        open={open}
        onOk={handleOk}
        okText= "Confirm"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
      </div>

      <Typography.Title level={5} style={{ marginTop: 10 }}>
        Transactions
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={payments?.transactions?.items}
        loading={payments?.transactionLoading}
        rowKey="uid"
      />
    </>
  );
}

export default Payments;
