import { message, notification, Button } from "antd";
import Axios from "axios";

var interval;
var guestLogin;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const requestEntry = (data) => async (dispatch) => {
  console.log("inside requestEntry");
  Axios.post(BASE_URL + `/api/requestEntry`, data, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      console.log("result",res.data);
      dispatch({
        type: "SET_REQUEST_SENT",
        payload: true,
      });
      dispatch({
        type: "SET_TRANSACTION_ID",
        payload: res.data.transaction_id,
      });
      console.log("Sucess");

      interval = setInterval(function () {
        dispatch(
          getLoanDetails({
            dealer_id: res.data.dealer_id,
            memberId: res.data.memberId,
            cardId: res.data.cardId,
          })
        );
      }, 5000);
    }
  }).catch((err)=>{
    console.log(err)
    if(err.response.status === 422){
      message.error("No Records Found For Card Number.",10)
    }
    else if(err.response.status === 409){
      message.error("Loan already processed for this card number.",10)
    }
    else if(err.response.status === 406){
      message.error("A transaction already exisits for the card number for your dealership. Please continue with the same transaction.",10)
    }
    else if(err.response.data.message){
      message.error(err.response.data.message,5)
    }
    else{
      message.error("Error occured please try again",10)
    }
  });
};
export const guestRequestEntry = (data) => async (dispatch) => {
  console.log("inside guestRequestEntry");
  Axios.post(BASE_URL + `/api/guestRequestEntry`, data, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      console.log("result",res.data);
      dispatch({
        type: "SET_REQUEST_SENT",
        payload: true,
      });
      dispatch({
        type: "SET_GUEST_LOAN_DATA",
        payload: data,
      });
      console.log("Sucess");

      guestLogin = setInterval(function () {
        dispatch(
          getGuestLoanDetails({
            dealer_nm: res.data.dealer_nm,
            memberId: res.data.memberId,
            cardId: res.data.loan_id,
            email: res.data.email,
          })
        );
      }, 5000);
    }
  }).catch((err)=>{
    console.log(err)
    if(err.response.status === 422){
      message.error("No Records Found For Card Number.",10)
    }
    else if(err.response.status === 409){
      message.error("Loan already processed for this card number.",10)
    }
    else if(err.response.status === 406){
      message.error("A transaction already exisits for the card number for your dealership. Please continue with the same transaction.",10)
    }
    else if(err.response.data.message){
      message.error(err.response.data.message,5)
    }
    else{
      message.error("Error occured please try again",10)
    }
  });
};
export const getLoanDetails = (data) => async (dispatch) => {
  console.log("inside getLoanDetails");
  console.log("loan details", data);

  Axios.get(BASE_URL + `/api/loanData/${data.memberId}/${data.cardId}/${data.dealer_id}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    console.log(res)
    if (res.status === 200 && res.data !== "Access Invalid") {
      clearInterval(interval);
      console.log(res.data);
      let loandata = res.data[0];
      loandata.cardId = data.cardId;

      dispatch({
        type: "SET_LOAN_DATA",
        payload: loandata,
      });
      dispatch({
        type: "SET_LOAN_ACCESS",
        payload: true,
      });
      console.log("Sucess");
    } else {
      dispatch({
        type: "SET_LOAN_DATA",
        payload: false,
      });
    }
  });
};
export const getGuestLoanDetails = (data) => async (dispatch) => {
  console.log("inside getLoanDetails");
  console.log("loan details", data);

  Axios.get(BASE_URL + `/api/guestLoanData/${data.memberId}/${data.cardId}/${data.dealer_nm}/${data.email}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    console.log(res)
    if (res.status === 200 && res.data !== "Access Invalid") {
      clearInterval(guestLogin);
      console.log(res.data);
      let loandata = res.data[0];
      loandata.cardId = data.cardId;

      dispatch({
        type: "SET_LOAN_DATA",
        payload: loandata,
      });
      dispatch({
        type: "SET_LOAN_ACCESS",
        payload: true,
      });
      console.log("Sucess");
    } else {
      dispatch({
        type: "SET_LOAN_DATA",
        payload: false,
      });
    }
  });
};

export const checkAccessValid = (data) => async (dispatch) => {
  console.log(data)
  console.log("inside");
  Axios.post(BASE_URL + `/api/checkLoanAccess`, data, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    console.log("CHECK LOAN ACCESS", res)
    if (res.status === 200 && res.data === true) {
      dispatch({
        type: "SET_LOAN_ACCESS",
        payload: true,
      });
      console.log("Sucess");
    }
  });
};

export const createNewTransaction = (data) => async (dispatch) => {
  console.log("inside createNewTransaction");
  Axios.post(BASE_URL + `/api/createRequest`, data, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      console.log("createNewTransaction",res.data);
      dispatch({
        type: "SET_TRANSACTION_ID",
        payload: res.data.transaction_id,
      });
      console.log("Sucess");
    }
  });
};


export const getTransaction = (data) => async (dispatch) => {
  console.log("inside getTransaction");
  Axios.get(BASE_URL + `/api/transactions?id=${data.dealerhost_id}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      console.log("getTransaction",res.data);
      dispatch({
        type: "SET_TRANSACTIONS",
        payload: res.data,
      });
      console.log("Sucess");
    }
  });
};
export const getDocuments = (transaction_id) => async (dispatch) => {
  console.log("inside getDocuments");
  Axios.get(BASE_URL + `/api/documents/${transaction_id}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      console.log("createNewTransaction",res.data);
      dispatch({
        type: "SET_DOCUMENTS",
        payload: res.data,
      });
      console.log("Got document urls");
    }
  });
};
export const sendSubmitRequest = (data) => async (dispatch) => {
  console.log("inside sendSubmitRequest");
  Axios.post(BASE_URL + `/api/transaction/submitRequest`,data, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      message.success(res.data.message)
      interval = setInterval(function () {
        dispatch(
          getTransactionById(data.id)
        );
      }, 5000);
    }
  });
};
export const getTransactionById = (id) => async (dispatch) => {
  console.log("inside getTransactionById");
  Axios.get(BASE_URL + `/api/transactions/${id}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200 && res.data[0]?.status === 'Completed' ) {
      clearInterval(interval);
      dispatch({
        type: "SET_CURRENT_TRANSACTION",
        payload: res.data[0],
      });
    }
    else if (res.status === 200 ) {
      console.log("getTransactionById",res.data);
      dispatch({
        type: "SET_CURRENT_TRANSACTION",
        payload: res.data[0],
      });
    }
  });
};
export const invite = (data, form) => async (dispatch) => {
  console.log("inside invite");
  Axios.post(BASE_URL + `/api/dealerhosts/invite`, data, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      form.resetFields();
      message.success("Invitation sent successfully")
    }
  })
  .catch((err) =>{
    console.log(err)
    if(err && err.response && err.response.status===409)
      message.error("User already exists. Try using another email address",10)
    else
      message.error("An error occured please try again")
  })
};
export const getDealerHosts = (id) => async (dispatch) => {
  console.log("inside getDealerHosts");
  Axios.get(BASE_URL + `/api/team/${id}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200 && Array.isArray(res.data)) {
      dispatch({
        type: "SET_DEALERHOST_DATA",
        payload: res.data,
      });
    }
  })
  .catch((err) =>{
    message.error("An error occured. Please try again.")
  })
};
export const updateTransaction = (data) => async (dispatch) => {
  console.log("inside updateTransaction");
  Axios.put(BASE_URL + `/api/transactions`,data, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      dispatch({
        type: "SET_CURRENT_TRANSACTION",
        payload: res.data[0],
      });

      //dispatch(getLoanDetails({memberId:data.member_id, cardId: data.cardId, dealer_id:data.dealer_id}));
    }
  })
  .catch((err) =>{
    message.error("An error occured. Please try again.")
  })
};
export const getAllTransactions = (id) => async (dispatch) => {
  clearInterval(interval);
  console.log("inside getAllTransactions");
  Axios.get(BASE_URL + `/api/transactions/all?id=${id}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      dispatch({
        type: "SET_ALL_TRANSACTIONS",
        payload: res.data,
      });
    }
  })
  .catch((err) =>{
    message.error("An error occured. Please try again.")
  })
};
export const getCurrentDealerHost = (email,handleLogout) => async (dispatch) => {
  console.log("inside getCurrentDealerHost");
  Axios.get(BASE_URL + `/api/dealerhostInfo/${encodeURIComponent(email)}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() =>{
        localStorage.clear();
        handleLogout();
    }}>
        Signup
      </Button>
    );
    if (res.status === 200) {
      console.log(res.data)
      if (res.data[0] === undefined && window.location.pathname !=="/signup") {
        console.log(window.location.pathname)
        
        notification.warning({
          description: "It appears that the email is not currently registered in our system. Please Signup to create your account.",//"No user found for the email. Please contact your supervior or signup your dealership",
          duration: 0,
          onClose: () => {
            localStorage.clear();
            handleLogout();
        },
        btn,
    key,
    placement:'top',

      })
      }
      dispatch({
        type: "SET_CURRENT_DEALERHOST_DATA",
        payload: res.data[0],
      });
    }
  })
  .catch((err) =>{
    message.error("An error occured. Please try again.")
  })
};
export const getVinData = async (vin, transaction) => {
  try{
  let res = await Axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${vin}?format=JSON`);
  console.log("RES",res.data.Results[0])
    return res.data.Results[0]
  }
  catch(err){
    message.error("An error occured. Please try again.")
  }
};

export const signupDealership = (data) => async (dispatch) => {
  console.log("inside signupDealership");
  Axios.post(BASE_URL + `/api/dealership`, data, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() =>{
        localStorage.clear();window.location.href = '/';
    }}>
        Login
      </Button>
    );
    if(res.data.statusCode && res.data.statusCode===200){
      notification.success({
        description:
        res.data.message,
        duration: 0,
        onClose: ()=>{window.location.href = '/'; dispatch({
          type: "RESET_LOADING"
        });
      },
      btn,
  key,
  placement:'top'
      })

      
      }
    else if (res.status === 200)  {
       dispatch({
      type: "RESET_LOADING"
    });
      notification.warning({
        description:
        res.data.message,
        duration: 0
      })
    }
     
    
  })
  .catch((err) =>{dispatch({
    type: "RESET_LOADING"
  });
    notification.error({
      message: 'Error',
      description:"An error occured. Please try again.",
      duration: 0
    })
  })
};

export const getintrestRates = (data) => async (dispatch) => {
  console.log("inside getintrestRates");
  console.log(data)
  Axios.get(BASE_URL + `/api/intrestRates?lender_id=${data.lender_id}&credit_score=${data.credit_score}`, {
    headers: {
      Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
    },
  }).then((res) => {
    if (res.status === 200) {
      console.log("INTREST_RATES",res.data)
      dispatch({
        type: "SET_INTREST_RATES_DATA",
        payload: res.data,
      });
    }
  })
  .catch((err) =>{
    message.error("An error occured. Please try again.")
  })
};
export const getBalance = (dealer_id, token) => async (dispatch) => {
  dispatch({
    type: "SET_BALANCE_LOADING",
  });
  let data = {
    type_id: `'${dealer_id}'`,
    type: "DEALER",
  };
  Axios.post(BASE_URL + `/api/payments/balance`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: "SET_WALLET_BALANCE",
          payload: res.data,
        });
        dispatch({
          type: "RESET_BALANCE_LOADING",
        });
      }
    })
    .catch((err) => {
      message.error("An error occured. Please try again.");
    });
};

export const getWalletTransactions = (dealer_id, token) => async (dispatch) => {
  dispatch({
    type: "SET_TRANSACTIONS_LOADING",
  });
  var firstDay = "2022-01-02"; //moment().startOf('month').format('DD-MM-YYYY');
  var lastDay = "2024-01-01"; //moment().endOf('month').format("DD-MM-YYYY");
  let data = {
    type_id: `'${dealer_id}'`,
    type: "DEALER",
    start_date: firstDay,
    end_date: lastDay,
  };
  Axios.post(BASE_URL + `/api/payments/transactions`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: "SET_WALLET_TRANSACTIONS",
          payload: res.data,
        });
        dispatch({
          type: "RESET_TRANSACTIONS_LOADING",
        });
      }
    })
    .catch((err) => {
      message.error("An error occured. Please try again.");
    });
};

export const withdrawFunds = (dealer_id, token, user) => async (dispatch) => {
  dispatch({
    type: "SET_TRANSACTIONS_LOADING",
  });

  let data = {
    type_id: `'${dealer_id}'`,
    type: "DEALER",
    user: user,
  };
  Axios.put(BASE_URL + `/api/payments/withdraw`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(getWalletTransactions(dealer_id, token));
        message.success("Transfer Successful");
      }
    })
    .catch((err) => {
      message.error("An error occured. Please try again.");
      dispatch({
        type: "RESET_TRANSACTIONS_LOADING",
      });
    });
};