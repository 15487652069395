import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import requestEntryReducer from "./requestEntryReducer";
import loanDataReducer from "./loanDataReducer";
import transactionReducer from "./transactionReducer";
import documentReducer from "./documentReducer";
import dealerHostReducer from "./dealerHostReducer";
import loadingReducer from "./loadingReducer";
import paymentsReducer from "./paymentsReducer";

const rootReducer = combineReducers({
    errors: errorReducer,
    requestEntry: requestEntryReducer,
    loanData: loanDataReducer,
    transaction:transactionReducer,
    documents:documentReducer,
    dealerHosts: dealerHostReducer,
    loading: loadingReducer,
    payments: paymentsReducer
  });
  
  export default rootReducer;